function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';
/**
 * Middleware that handles `overrides` key overrides.
 * @type {ThemeMiddleware}
 */

export function muiOverridesMiddleware(baseOverrides, customOverrides) {
  var _baseOverrides$overri = baseOverrides.overrides,
      baseMuiOverrides = _baseOverrides$overri === void 0 ? {} : _baseOverrides$overri,
      restBaseOverrides = _objectWithoutProperties(baseOverrides, ["overrides"]);

  var _customOverrides$over = customOverrides.overrides,
      inputMuiOverrides = _customOverrides$over === void 0 ? {} : _customOverrides$over,
      restInputOverrides = _objectWithoutProperties(customOverrides, ["overrides"]); // Merge everything except for muiOverrides overrides


  var overrides = merge({}, restBaseOverrides, restInputOverrides); // For both standard typography overrides and muiTypography overrides map them
  // with their respective format function and merge base with input.

  var muiOverrides = merge.apply(void 0, [{}].concat(_toConsumableArray([baseMuiOverrides, inputMuiOverrides].map(function (muiOverrides) {
    return isFunction(muiOverrides) ? muiOverrides(overrides) : muiOverrides;
  }))));
  return _objectSpread({}, customOverrides, {
    overrides: muiOverrides
  });
}
export default muiOverridesMiddleware;