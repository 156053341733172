import invariant from 'invariant';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { createAction } from 'redux-act';
import { EventTypes } from 'redux-segment';
import { AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAILURE, AUTH_LOGOUT, SET_ACTIVE_ACCOUNT } from './constants';
/**
 * Actions
 */

/**
 * Dispatched when the auth flow starts.
 * @type {ActionCreator}
 * @returns {FluxStandardAction} - AUTH_REQUEST action
 */

export var authRequest = createAction(AUTH_REQUEST);
/**
 * Dispatched when a user has successfully authenticated.
 * @type {ActionCreator}
 * @param {String} authToken - Auth token
 * @returns {FluxStandardAction} - AUTH_SUCCESS action
 */

export var authSuccess = createAction(AUTH_SUCCESS);
/**
 * Dispatched when a user fails to authenticate.
 * @type {ActionCreator}
 * @param {String} authError - Authentication error
 * @returns {FluxStandardAction} - AUTH_FAILURE action
 */

export var authFailure = createAction(AUTH_FAILURE, function (error) {
  return new Error(error);
});
/**
 * Dispatched when a user is logged out.
 * @type {ActionCreator}
 * @returns {FluxStandardAction} - AUTH_LOGOUT action
 */

export var logout = createAction(AUTH_LOGOUT, null, function () {
  return {
    analytics: {
      eventType: EventTypes.reset
    }
  };
});
/**
 * Sets the active account, which determines which dealer's insights, performance
 * data, chats, etc. show up in the UI.
 * @type {ActionCreator}
 * @param {Object} account - Account to set as active
 * @param {String} account.id - Token used in `X-PC-ACCESS` header
 * @param {String} account.accountId - Id for the account
 * @param {String} account.name - Name of the account
 * @returns {FluxStandardAction} - SET_ACTIVE_ACCOUNT action
 */

export var setActiveAccount = createAction(SET_ACTIVE_ACCOUNT, null, function (payload) {
  // Validate that payload includes a valid account id
  invariant(isString(payload.accountId) && !isEmpty(payload.accountId), '(lib/user/actions) setActiveAccount: Expected valid account details');
  var groupId = payload.accountId,
      name = payload.name;
  return {
    analytics: {
      eventType: EventTypes.group,
      eventPayload: {
        groupId: groupId,
        traits: {
          name: name
        }
      }
    }
  };
});