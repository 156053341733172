import action from './action';
import children from './children';
import component from './component';
import layout from './layout';
import derivePropTypeFromValueOf from './derivePropTypeFromValueOf';
import mutuallyExclusiveProps from './mutuallyExclusiveProps';
import mutuallyExclusiveProperties from './mutuallyExclusiveProperties';
export { action, children, component, layout, derivePropTypeFromValueOf, mutuallyExclusiveProps, mutuallyExclusiveProperties };
export default {
  action: action,
  children: children,
  component: component,
  layout: layout,
  derivePropTypeFromValueOf: derivePropTypeFromValueOf,
  mutuallyExclusiveProps: mutuallyExclusiveProps,
  mutuallyExclusiveProperties: mutuallyExclusiveProperties
};