import { matchPath } from 'react-router-dom';
/**
 * Location key generation
 */

/**
 * This object maps paths to functions that will be used to
 * generate location keys for that route. This was originally created
 * to allow a route that may match different paths to prevent unmounting
 * and re-mounting of the route's component via the transition group. If two locations
 * supply the same key, the route will not unmount and remount allowing for state
 * continuity of the component rendered.
 */

var locationKeyOverrides = {};
/**
 * This function will find proper location key overrides, if they exist, and fallback
 * to a default location key if it does not. Unique keys are required for routes
 * that require transitions.
 * @param {object} location - the location object
 */

export default function routeKeyGenerator(location) {
  var pathname = location.pathname,
      search = location.search;
  var override = locationKeyOverrides[Object.keys(locationKeyOverrides).find(function (path) {
    return matchPath(pathname, {
      path: path,
      exact: true
    });
  })];
  var routeKey = "".concat(pathname).concat(search);

  if (override) {
    routeKey = override(location) || routeKey;
  }

  return routeKey;
}