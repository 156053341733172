import { createSelector } from 'reselect';
import { createThemeSelector, createVariableThemeSelectorCreator } from './utils';
import { paletteCommonSelector, palettePrimarySelector, paletteSecondarySelector, paletteGreySelector, paletteTextSelector, paletteSuccessSelector, paletteErrorSelector, paletteWarnSelector } from './palette'; // Color selectors

export var colorWhiteSelector = createSelector(paletteCommonSelector, function (common) {
  return common.white;
});
/**
 * Given a palette theme selector, and optionally a default value to use as `hue`,
 * this util uses {@link createVariableThemeSelectorCreator} to create a variable
 * theme selector creator. The returned selector creator takes in a `hue` value
 * and returns a selector that will fetch the proper hue from the palette using
 * the palette selector provided initially.
 * @param {ReduxSelector} paletteSelector - theme selector for a palette
 * @param {String} defaultHue - default hue to use
 * @return {VariableThemeSelectorCreator}
 */

export var createVariableColorSelectorCreator = function createVariableColorSelectorCreator(paletteSelector) {
  var defaultHue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'main';
  return createVariableThemeSelectorCreator( // selector creator
  function () {
    var hue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultHue;
    return createThemeSelector(paletteSelector, function (palette) {
      return palette[hue];
    });
  }, // generate cache key (must account for default value)
  function () {
    var hue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultHue;
    return hue;
  });
}; // Variable color selector getters

export var getColorPrimarySelector = createVariableColorSelectorCreator(palettePrimarySelector, 'main');
export var getColorSecondarySelector = createVariableColorSelectorCreator(paletteSecondarySelector, 'main'); // The `hue` value provided to `getColorGreySelector` can be the name of a hue,
// or optionally a grey variant value (e.g. 400)

export var getColorGreySelector = createVariableColorSelectorCreator(paletteGreySelector, 'main');
export var getColorTextSelector = createVariableColorSelectorCreator(paletteTextSelector, 'primary');
export var getColorSuccessSelector = createVariableColorSelectorCreator(paletteSuccessSelector, 'main');
export var getColorErrorSelector = createVariableColorSelectorCreator(paletteErrorSelector, 'main');
export var getColorWarnSelector = createVariableColorSelectorCreator(paletteWarnSelector, 'main');