import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withScrollFix } from '@signal/components/ScrollToTop'
import componentPropType from '@signal/prop-types/component'
import { Wrapper, Content } from './styles'

const ContentWithScrollFix = withScrollFix(Content)

export const LayoutDefault = memo(props => {
  const { wrapperComponent: WrapperComponent, contentProps, children } = props

  return (
    <WrapperComponent>
      <ContentWithScrollFix {...contentProps}>{children}</ContentWithScrollFix>
    </WrapperComponent>
  )
})

LayoutDefault.propTypes = {
  /**
   * The component used for the wrapper node.
   * Either a string to use a DOM element or a component.
   */
  wrapperComponent: componentPropType,
  /**
   * These props will be forwarded to the page content component.
   */
  contentProps: PropTypes.object
}

LayoutDefault.defaultProps = {
  wrapperComponent: Wrapper
}

export default LayoutDefault
