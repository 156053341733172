import kebabCase from 'lodash/kebabCase';
import { createRoute } from '@signal/router';
export var HOME = createRoute();
export var NOT_FOUND = createRoute('not-found');
export var LOGIN = createRoute('login');
export var LOGOUT = createRoute('logout');
export var PROFILE = createRoute('profile');
export var ACCOUNTS = createRoute(PROFILE, 'accounts');
export var ACCOUNT = createRoute('account/:accountId');
export var MANAGE_SPEND = createRoute(ACCOUNT, 'manage-spend');
export var PERFORMANCE = createRoute(ACCOUNT, 'performance');
export var PERFORMANCE_OVERVIEW = createRoute(PERFORMANCE, ':activeTab');
export var PERFORMANCE_CHANNELS = createRoute(PERFORMANCE, 'channels');
export var PERFORMANCE_CHANNELS_DETAIL = createRoute(PERFORMANCE_CHANNELS, 'detail');
export var PERFORMANCE_CHANNELS_VIN = createRoute(PERFORMANCE_CHANNELS, 'vin/:vin');
export var PERFORMANCE_INVENTORY = createRoute(PERFORMANCE, 'inventory');
export var PERFORMANCE_INVENTORY_DETAIL = createRoute(PERFORMANCE_INVENTORY, 'detail');
export var PERFORMANCE_INVENTORY_DETAIL_VIN = createRoute(PERFORMANCE_INVENTORY_DETAIL, 'vin/:vin');
export var PERFORMANCE_INVENTORY_VIN = createRoute(PERFORMANCE_INVENTORY, 'vin/:vin');
export var PERFORMANCE_SHOPPERS = createRoute(PERFORMANCE, 'shoppers');
export var PERFORMANCE_SHOPPERS_BUCKET = createRoute(PERFORMANCE_SHOPPERS, ':shopperBucket');
export var SHOPPER_DETAIL = createRoute(PERFORMANCE_SHOPPERS_BUCKET, ':shopperId');
export var createPerformanceTab = function createPerformanceTab(label) {
  return {
    key: kebabCase(label),
    label: label
  };
};
export var PERFORMANCE_TAB_CHANNELS = createPerformanceTab('Channels');
export var PERFORMANCE_TAB_INVENTORY = createPerformanceTab('Inventory');
export var PERFORMANCE_TABS = [PERFORMANCE_TAB_CHANNELS, PERFORMANCE_TAB_INVENTORY];
export var INSIGHTS = createRoute(ACCOUNT, 'insights');
export var INSIGHT_DETAIL = createRoute(INSIGHTS, ':insightId');
export var INSIGHT_CATEGORIES = createRoute(INSIGHTS, 'categories');
export var INSIGHT_CATEGORY_DETAIL = createRoute(INSIGHT_CATEGORIES, ':categoryId');
export var ADS = createRoute(ACCOUNT, 'ads');
export var AD_DETAIL = createRoute(ADS, ':adId/edit');
export var AD_STATS = createRoute(ADS, ':adId/stats');
export var AD_CREATE = createRoute(ADS, 'create');
export var AUDIENCES = createRoute(ACCOUNT, 'audiences');
export var AUDIENCE_DETAIL = createRoute(AUDIENCES, ':audienceId/edit');
export var AUDIENCE_CREATE = createRoute(AUDIENCES, 'create');
export var BOOKMARKS = createRoute(ACCOUNT, 'bookmarks');
export var CHATS = createRoute(ACCOUNT, 'chats');
export var CHAT_NEW = createRoute(CHATS, 'new');
export var CHAT_DETAIL = createRoute(CHATS, ':chatId');