var _createReducer;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { setPageTransitioning, serviceWorkerAwaitingActivation, acceptServiceWorkerUpdate, dismissServiceWorkerUpdate } from './actions';
var initialState = {
  version: process.env.REACT_APP_VERSION,
  isUpdateAvailable: false
};

var handleUpdateDecision = function handleUpdateDecision(state) {
  return _objectSpread({}, state, {
    isUpdateAvailable: false
  });
};

export default createReducer((_createReducer = {}, _defineProperty(_createReducer, setPageTransitioning, function (state, isPageTransitioning) {
  return _objectSpread({}, state, {
    isPageTransitioning: isPageTransitioning
  });
}), _defineProperty(_createReducer, serviceWorkerAwaitingActivation, function (state) {
  return _objectSpread({}, state, {
    isUpdateAvailable: true
  });
}), _defineProperty(_createReducer, acceptServiceWorkerUpdate, handleUpdateDecision), _defineProperty(_createReducer, dismissServiceWorkerUpdate, handleUpdateDecision), _createReducer), initialState);