function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as signalRouterReducer, REDUCER_KEY as SIGNAL_ROUTER_REDUCER_KEY } from './router';
import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import appReducer from './app/reducer';
import localReducer from './local/reducer';
import { setActiveAccount } from './auth/actions';
/**
 * We don't want to reset these pieces of state when the user account is
 * changed, but we do want to reset everything else. State located at the other
 * keys will be reset by it's reducer to it's initial value.
 * @param {Object} state - initial state
 * @return {Object} - state minus keys to reset
 */

export var resetAccountState = function resetAccountState(_ref) {
  var router = _ref.router,
      user = _ref.user,
      app = _ref.app,
      auth = _ref.auth,
      local = _ref.local;
  return {
    router: router,
    user: user,
    app: app,
    auth: auth,
    local: local
  };
};
/**
 * Enhances the provided reducer with logic that resets the necessary state in
 * the store when a user changes accounts.
 * @param {ReduxReducer} reducer - reducer to enhance
 * @return {ReduxReducer} - enhanced reducer
 */

export var createRootReducer = function createRootReducer(reducer) {
  return function (state, action) {
    if (action.type === setActiveAccount.getType()) {
      /**
       * We don't want to reset these pieces of state when the user account is
       * changed, but we do want to reset everything else.
       * For the pieces of the state that are not set below, they will be reset
       * to the initial state.
       */
      return reducer(resetAccountState(state), action);
    }

    return reducer(state, action);
  };
};
/**
 * Creates a new root reducer for the app. It optionally accepts a dynamic set
 * of reducers that should be combined with the app's static reducers.
 * @param {Object.<String,ReduxReducer>} dynamicReducers - dynamic set of reducers
 * @param {Object} history - history used by react router
 * @return {ReduxReducer} - a new root app reducer
 */

export function createReducer(dynamicReducers, history) {
  return createRootReducer(combineReducers(_objectSpread(_defineProperty({
    router: connectRouter(history),
    auth: authReducer,
    user: userReducer,
    app: appReducer,
    local: localReducer
  }, SIGNAL_ROUTER_REDUCER_KEY, signalRouterReducer), dynamicReducers)));
}
export default createReducer;
/**
 * @global
 * @typedef {Function} ReduxReducer
 * A pure function that takes the previous state from the redux store and an
 * action, and returns the next state.
 *
 * @param {Object} previousState - Store state before the action was dispatched
 * @param {FluxStandardAction} action - Action that was dispatched
 * @returns {Object} - Next state
 */

/**
 * @global
 * @typedef {Function} ReduxSelector
 * Convenience method that takes in the redux state tree, and returns a value
 * from the state, potentially a derived value.
 *
 * @param {Object} state - The current redux state
 * @returns {*}
 */

/**
 * @global
 * @typedef {Function} ActionCreator
 * Convenience method that takes in any number of arguments and formats them to
 * create a {@link FluxStandardAction} with a specific action type.
 *
 * @param {...*} args - The data used by the action creator to create the action
 * @returns {FluxStandardAction}
 */

/**
 * @global
 * @typedef {Object} FluxStandardAction
 * An [FSA]{@link https://github.com/redux-utilities/flux-standard-action} compliant
 * redux action.
 *
 * @property {String} type
 * The `type` of an action identifies to the consumer the nature of the action
 * that has occurred. `type` is a string constant. If two types are the same,
 * they MUST be strictly equivalent (using `===`).
 *
 * @property {({error} === true) ? Error : *} [payload]
 * The optional `payload` property MAY be any type of value. It represents the
 * payload of the action. Any information about the action that is not the type
 * or status of the action should be part of the payload field.
 *
 * By convention, if `error` is true, the payload SHOULD be an error object. This
 * is akin to rejecting a promise with an error object.
 *
 * @property {Boolean} [error]
 * The optional `error` property MAY be set to `true` if the action represents
 * an error.
 *
 * An action whose `error` is true is analogous to a rejected Promise. By
 * convention, the `payload` SHOULD be an error object.
 *
 * If `error` has any other value besides `true`, including `undefined` and `null`,
 * the action MUST NOT be interpreted as an error.
 *
 * @property {*} [meta]
 * The optional `meta` property MAY be any type of value. It is intended for any
 * extra information that is not part of the payload.
 */