import property from 'lodash/property';
import { createSelector, createStructuredSelector } from 'reselect';
import isServer from '@signal/utils/env/isServer';
import isTokenValid from './utils/isTokenValid';
export var rootSelector = property('auth');
export var tokenSelector = createSelector(rootSelector, property('token'));
export var expiresSelector = createSelector(rootSelector, property('expires'));
export var tokenDetailsSelector = createStructuredSelector({
  token: tokenSelector,
  expires: expiresSelector
});
export var isTokenValidSelector = createSelector(tokenDetailsSelector, isTokenValid);
export var isAuthenticatingSelector = createSelector(rootSelector, property('authenticating'));
export var isAuthenticatedSelector = createSelector(tokenSelector, Boolean);
export var isInitializedSelector = createSelector(rootSelector, property('isInitialized'));
export var activeAccountSelector = createSelector(rootSelector, property('activeAccount'));
export var hasActiveAccountSelector = createSelector(activeAccountSelector, Boolean);
export var errorSelector = createSelector(rootSelector, property('error'));
export var errorMessageSelector = createSelector(errorSelector, property('message'));
export var activeAccountTokenSelector = createSelector(activeAccountSelector, property('id'));
export var activeAccountIdRawSelector = createSelector(activeAccountSelector, property('accountId'));
export var activeAccountFeaturesSelector = createSelector(activeAccountSelector, property('features'));
export var activeAccountIdSelector = createSelector(activeAccountIdRawSelector, // returns fake ID if on the server so react-snapshot can generate routes
function (accountId) {
  return !isServer() ? accountId : '00000000-0000-0000-0000-000000000000';
});
export var activeAccountNameSelector = createSelector(activeAccountSelector, property('name'));