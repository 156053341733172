export var LOCAL_STORAGE_TOKEN_KEY = 'pc_authToken';
export var LOCAL_STORAGE_ACTIVE_ACCOUNT_KEY = 'pc_activeAccount';
export var REFRESH_WINDOW = 1000 * 60 * 15; // Refresh 15 min before token expires

export var CMD_CREATE_SESSION = 'createAuthSession';
export var CMD_DESTROY_SESSION = 'destroyAuthSession';
/**
 * Action Types
 */

export var AUTH_REQUEST = 'AUTH/REQUEST';
export var AUTH_SUCCESS = 'AUTH/SUCCESSFUL';
export var AUTH_FAILURE = 'AUTH/FAILURE';
export var AUTH_LOGOUT = 'AUTH/LOGOUT';
export var SET_ACTIVE_ACCOUNT = 'AUTH/SET_ACTIVE_ACCOUNT';