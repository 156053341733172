import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-detail-chart" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-performance-query" */ '@signal/core/analyticsMapping'
    ),
  loadData: (
    {
      exploreDataLoader,
      channelsDataLoader,
      hierarchyDataLoader,
      rulesDataLoader
    },
    loaderInterface
  ) => {
    exploreDataLoader(loaderInterface)
    channelsDataLoader(loaderInterface)
    hierarchyDataLoader(loaderInterface)
    rulesDataLoader(loaderInterface)
  },
  loading: () => null,
  error: combinedError(ErrorScreenApi)
})
