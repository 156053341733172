function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { PERFORMANCE_TABS } from '../routes';
import { setActivePerformanceTab } from './actions';
/**
 * @global
 * @name signalRouterState
 * The state tree for the Signal router reducer.
 * @property {string} activeTab - The active performance tab key
 */

export var initialState = {
  activePerformanceTab: PERFORMANCE_TABS[0].key
  /**
   * @global
   * @name signalRouterReducer
   * Reducer for the Signal router related application state
   * @type {ReduxReducer}
   */

};
export default createReducer(_defineProperty({}, setActivePerformanceTab, function (state, activePerformanceTab) {
  return _objectSpread({}, state, {
    activePerformanceTab: activePerformanceTab
  });
}), initialState);