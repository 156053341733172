import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import createDeepEqualSelector from '@signal/utils/createDeepEqualSelector';
export var rootSelector = function rootSelector(state) {
  return get(state, 'user');
};
/**
 * User Details Selectors
 */

export var detailsRootSelector = createDeepEqualSelector(rootSelector, function (user) {
  return get(user, 'me');
});
export var detailsLoadedSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'id') !== null;
});
export var detailsLoadingSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'loading');
});
export var userIdSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'id');
});
export var firstNameSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'firstName');
});
export var lastNameSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'lastName');
});
export var nameSelector = createSelector(firstNameSelector, lastNameSelector, function (firstName, lastName) {
  return [firstName, lastName].filter(Boolean).join(' ');
});
export var initialsSelector = createSelector(firstNameSelector, lastNameSelector, function (firstName, lastName) {
  return [firstName, lastName].filter(Boolean).map(function (name) {
    return name[0];
  }).join('');
});
export var emailSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'email');
});
/**
 * User Accounts Selectors
 */

export var accountsRootSelector = createDeepEqualSelector(rootSelector, function (user) {
  return get(user, 'accounts');
});
export var accountsListSelector = createSelector(accountsRootSelector, function (accounts) {
  return get(accounts, 'list');
});
export var accountsByIdSelector = createSelector(accountsRootSelector, function (accounts) {
  return get(accounts, 'byId');
});
export var accountsLoadedSelector = createSelector(accountsListSelector, function (accountsList) {
  return accountsList !== null;
});
export var accountsLoadingSelector = createSelector(accountsRootSelector, function (accounts) {
  return get(accounts, 'loading');
});
export var accountsSelector = createSelector(accountsListSelector, accountsByIdSelector, function (accountsList, accountsById) {
  if (!accountsList) return null;
  var accounts = accountsList.map(function (id) {
    return get(accountsById, id);
  });
  return sortBy(accounts, 'name');
});
export var accountDetailsSelector = createSelector( // eslint-disable-next-line no-unused-vars
function (state, _ref) {
  var accountId = _ref.accountId;
  return accountId;
}, accountsByIdSelector, function (id, accounts) {
  return get(accounts, id);
});
/**
 * Users Selectors
 */

export var usersRootSelector = createDeepEqualSelector(rootSelector, function (user) {
  return get(user, 'users');
});
export var usersLoadedSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'list') !== null;
});
export var usersLoadingSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'loading');
});
export var usersListSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'list');
});
export var usersByIdSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'byId');
});
export var usersSelector = createSelector(usersListSelector, usersByIdSelector, function (usersList, usersById) {
  if (!usersList) return null;
  var users = usersList.map(function (id) {
    return get(usersById, id);
  });
  return sortBy(users, 'name');
});