import React from 'react'
import SignalRouter from '@signal/containers/Router'
import LoadableContext from '@signal/hoc/loadable/context'
import routerConfig from './routes'
import theme from './theme'
import { authLoader } from './core/auth'
import './styles/globals.css'

/**
 * Admin app-specific overrides
 */
import AppProvider from './core/AppProvider'

const loaderDependencyInjections = [authLoader]

export default function App() {
  return (
    <AppProvider theme={theme}>
      <LoadableContext.Provider value={loaderDependencyInjections}>
        <SignalRouter config={routerConfig} animateRoutes={true} />
      </LoadableContext.Provider>
    </AppProvider>
  )
}
