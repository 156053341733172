import invariant from 'invariant';
import every from 'lodash/every';
import partial from 'lodash/partial';
import has from 'lodash/has';
import overSome from 'lodash/overSome';
import overEvery from 'lodash/overEvery';
import defaults from 'lodash/defaults';
import conformsTo from 'lodash/conformsTo';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import negate from 'lodash/negate';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isLoaders from '../isLoaders';
import { SAGA_TYPES } from './constants';
/**
 * Expected loader definition shape
 * @type {Object}
 */

export var loaderDefinitionShape = {
  load: overSome([isFunction, isNil]),
  listen: overSome([isFunction, isNil]),
  injectReducers: overSome([isFunction, isNil]),
  dependencies: overEvery([isArray, isLoaders])
  /**
   * Valid loader definition properties
   * @type {Array}
   */

};
export var loaderDefinitionProperties = Object.keys(loaderDefinitionShape);
/**
 * Valid loader options properties
 * @type {Array}
 */

export var loaderOptionsProperties = ['LISTEN', 'LOAD', 'CANCEL', 'load', 'listen', 'injectReducers', 'sagaKeys', 'dependencies'];
/**
 * Expected loader replacement value shape
 * @type {Object}
 */

export var sagaReplacementShape = {
  key: overEvery([isString, negate(isEmpty)]),
  saga: isFunction
  /**
   * Validates the key provided to {@link createLoader}. Only non-empty strings
   * are considered valid.
   * @param {*} key - key to validate
   * @return {Boolean}
   */

};
export var validateLoaderKey = function validateLoaderKey(key) {
  return isString(key) && !isEmpty(key);
};
/**
 * Validates the definition provided to {@link createLoader}. Only objects that
 * match the shape of {@link loaderDefinitionShape} are considered valid.
 * @param {*} definition - definition to validate
 * @return {Boolean}
 */

export var validateLoaderDefinition = function validateLoaderDefinition(definition) {
  if (!definition) return;
  defaults(definition, {
    load: null,
    listen: null,
    injectReducers: null,
    dependencies: []
  });
  return conformsTo(definition, loaderDefinitionShape);
};
/**
 * Validates that the provided options object has all of the expected properties.
 * @param {Object} options - options to validate
 * @return {Boolean}
 */

export var validateLoaderOptions = function validateLoaderOptions(options) {
  return every(loaderOptionsProperties, partial(has, options));
};
/**
 * Validates a property provided to {@link DataLoader#replace}. Only properties
 * included in {@link loaderDefinitionProperties} are considered valid.
 * @param {*} property - property to validate
 * @return {Boolean}
 */

export var validateReplacementProperty = function validateReplacementProperty(property) {
  return loaderDefinitionProperties.includes(property);
};
/**
 * Validates a saga replacement provided to {@link DataLoader#replace}. Only
 * properties included in {@link loaderSagaProperties} are considered valid. The
 * provided value must also match the shape of {@link sagaReplacementShape}.
 * @param {*} property - property to validate
 * @param {*} value - value to validate
 * @return {Boolean}
 */

export var validateReplacementSaga = function validateReplacementSaga(property, value) {
  return !SAGA_TYPES.includes(property) || conformsTo(value, sagaReplacementShape);
};
export var validateReplacementInjectReducers = function validateReplacementInjectReducers(property, value) {
  return property !== 'injectReducers' || isFunction(value);
};
export var validateReplacementDependencies = function validateReplacementDependencies(property, value) {
  return property !== 'dependencies' || isArray(value);
};
/**
 * Validates a key and definition provided to {@link createLoader}. Uses
 * `invariant` to throw with a message if validation fails.
 * @param {*} key - loader key to validate
 * @param {*} definition - loader definition to validate
 */

export function validateLoaderParams(key, definition) {
  // Validate the provided key
  invariant(validateLoaderKey(key), '(lib/utils...) createLoader(): Expected a valid loader key'); // Validate the loader definition provided

  invariant(validateLoaderDefinition(definition), '(lib/utils...) createLoader(): Expected a valid loader definition');
}
/**
 * Validates a property and replacement value provided to {@link DataLoader#replace}.
 * Uses `invariant` to throw with a message if validation fails.
 * @param {*} property - property to validate
 * @param {*} replacement - replacement value to validate
 */

export function validateReplacementParams(property, replacement) {
  invariant(validateReplacementProperty(property), "(lib/utils...) createLoader().replace(): You may only replace valid loader definition keys (".concat(loaderDefinitionProperties.join(', '), ")")); // Validate replacement for saga properties

  invariant(validateReplacementSaga(property, replacement), '(lib/utils...) createLoader().replace(): Expected a valid saga replacement definition'); // Validate replacement for injectReducers

  invariant(validateReplacementInjectReducers(property, replacement), '(lib/utils...) createLoader().replace(): Expected a valid injectReducers replacement'); // Validate replacement for dependencies

  invariant(validateReplacementDependencies(property, replacement), '(lib/utils...) createLoader().replace(): Expected a valid injectReducers replacement');
}