import React, { Fragment } from 'react'
import FlexContainer from 'react-styled-flexbox'
import AnalyticsMappingContainer from '../../containers/AnalyticsMapping'
import Logout from '../../containers/Logout'

export default function AnalyticsMapping() {
  return (
    <Fragment>
      <FlexContainer justifyFlexEnd>
        <Logout />
      </FlexContainer>
      <AnalyticsMappingContainer />
    </Fragment>
  )
}
