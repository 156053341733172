var API_BASE = process.env.REACT_APP_SIGNAL_API_BASE;
var API_ENVIRONMENT = process.env.REACT_APP_SIGNAL_API_ENVIRONMENT;
var API_VERSION = process.env.REACT_APP_SIGNAL_API_VERSION;
export var API_PARTS = [API_BASE, API_ENVIRONMENT, API_VERSION];
export var API_URL = API_PARTS.filter(Boolean).join('/');
export var API_METHODS = {
  GET: 'GET',
  DELETE: 'DELETE',
  POST: 'POST',
  PUT: 'PUT'
};
export var API_REQUEST = Symbol('API_REQUEST');
/**
 * Audience Microservice
 */

var AUDIENCE_MICROSERVICE_BASE = process.env.REACT_APP_SIGNAL_AUDIENCE_MICROSERVICE_BASE;
var AUDIENCE_MICROSERVICE_VERSION = process.env.REACT_APP_SIGNAL_AUDIENCE_MICROSERVICE_VERSION;
export var AUDIENCE_MICROSERVICE_PARTS = [AUDIENCE_MICROSERVICE_BASE, AUDIENCE_MICROSERVICE_VERSION];
export var ANALYTICS_MAPPING_MICROSERVICE_PARTS = ['https://apix.purecars.com/analytics/mapping', 'v1'];
export var AUDIENCE_MICROSERVICE_URL = AUDIENCE_MICROSERVICE_PARTS.join('/');
/**
 * Native Microservice
 */

var NATIVE_MICROSERVICE_BASE = process.env.REACT_APP_SIGNAL_NATIVE_MICROSERVICE_BASE;
var NATIVE_MICROSERVICE_VERSION = process.env.REACT_APP_SIGNAL_NATIVE_MICROSERVICE_VERSION;
export var NATIVE_MICROSERVICE_PARTS = [NATIVE_MICROSERVICE_BASE, NATIVE_MICROSERVICE_VERSION];
export var NATIVE_MICROSERVICE_URL = NATIVE_MICROSERVICE_PARTS.join('/');
export var ANALYTICS_MAPPING_MICROSERVICE_URL = ANALYTICS_MAPPING_MICROSERVICE_PARTS.join('/');