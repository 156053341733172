import { HOME, NOT_FOUND } from '@signal/core/routes'
import AppliedRoute from '@signal/components/AppliedRoute'

// Layouts
import LayoutDefault from '../layouts/Default'
import NotFound from '../pages/NotFound'

// Pages
import AnalyticsMapping from '../pages/AnalyticsMapping'

export default {
  [HOME]: {
    layout: LayoutDefault,
    component: AnalyticsMapping,
    routeComponent: AppliedRoute,
    exact: true
  },
  // IMPORTANT: add the not found route as the last (fallback) route
  [NOT_FOUND]: {
    layout: LayoutDefault,
    component: NotFound,
    routeComponent: AppliedRoute
  }
}
