function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import * as constantsModule from '../variables';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import muiTypography from './typography.mui';
import muiOverrides from './overrides.mui';

var constants = _objectSpread({}, constantsModule);
/**
 * An object comprised of one or more {@link ThemeOverride} objects that is
 * provided to `createTheme` to override default theme values.
 * @typedef {Object.<string, ThemeOverride>} ThemeOverrides
 * @global
 */

/**
 * An object that is used to override default theme values.
 * @typedef {Object} ThemeOverride
 * @global
 */

/**
 * Material UI Theme Overrides
 * @type {ThemeOverrides}
 */


export var overrides = {
  breakpoints: breakpoints,
  constants: constants,
  palette: palette,
  typography: typography,
  muiTypography: muiTypography,
  overrides: muiOverrides
};
export { breakpoints, constants, palette };
export default overrides;