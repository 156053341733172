function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createAction } from 'redux-act';
import { API_REQUEST, API_METHODS } from './constants';
export var payloadReducer = function payloadReducer(actions, url, options, payloadReducers) {
  return {
    actions: actions,
    url: url,
    options: options,
    payloadReducers: payloadReducers
  };
};
export var metaReducer = function metaReducer(method) {
  return function () {
    var _ref;

    return _ref = {}, _defineProperty(_ref, API_REQUEST, true), _defineProperty(_ref, "method", method), _ref;
  };
};
export var createRequestAction = function createRequestAction(method) {
  return createAction("API_".concat(method), payloadReducer, metaReducer(method));
};
export var get = createRequestAction(API_METHODS.GET);
export var put = createRequestAction(API_METHODS.PUT);
export var post = createRequestAction(API_METHODS.POST);
export var del = createRequestAction(API_METHODS.DELETE);
export default {
  get: get,
  post: post,
  put: put,
  delete: del
};