function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { EventTypes } from 'redux-segment';
import invariant from 'invariant';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import createRequestActions, { REQUEST_ACTION_TYPES } from '@signal/utils/createRequestActions';
import { accounts, users } from './schemas';
import { USER_DETAILS, USER_ACCOUNTS, USER_USERS } from './constants';
/**
 * Actions
 */

/**
 * Actions for the user details API lifecycle.
 * @type {RequestActions}
 */

export var userDetailsActions = createRequestActions(USER_DETAILS, _defineProperty({}, REQUEST_ACTION_TYPES.SUCCESS, function (enhanceAction) {
  return enhanceAction(null, function (payload) {
    // Validate that payload includes a valid user id before identifying
    invariant(isString(payload.id) && !isEmpty(payload.id), '(lib/user/actions) userDetailsSuccess: Expected valid user details');
    var userId = payload.id;
    return {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: userId,
          traits: payload
        }
      }
    };
  });
}));
var userDetailsLoad = userDetailsActions.load,
    userDetailsRequest = userDetailsActions.request,
    userDetailsSuccess = userDetailsActions.success,
    userDetailsFailure = userDetailsActions.failure;
export { userDetailsLoad, userDetailsRequest, userDetailsSuccess, userDetailsFailure };
/**
 * Actions for the user accounts API lifecycle.
 * @type {RequestActions}
 */

export var userAccountsActions = createRequestActions(USER_ACCOUNTS, _defineProperty({}, REQUEST_ACTION_TYPES.SUCCESS, function (enhanceAction) {
  return enhanceAction(null, function () {
    return {
      schema: accounts
    };
  });
}));
var userAccountsLoad = userAccountsActions.load,
    userAccountsRequest = userAccountsActions.request,
    userAccountsSuccess = userAccountsActions.success,
    userAccountsFailure = userAccountsActions.failure;
export { userAccountsLoad, userAccountsRequest, userAccountsSuccess, userAccountsFailure };
/**
 * Actions for the users API lifecycle.
 * @type {RequestActions}
 */

export var usersActions = createRequestActions(USER_USERS, _defineProperty({}, REQUEST_ACTION_TYPES.SUCCESS, function (enhanceAction) {
  return enhanceAction(null, function () {
    return {
      schema: users
    };
  });
}));
var usersLoad = usersActions.load,
    usersRequest = usersActions.request,
    usersSuccess = usersActions.success,
    usersFailure = usersActions.failure;
export { usersLoad, usersRequest, usersSuccess, usersFailure };