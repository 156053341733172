import React from 'react';
import { connect } from 'react-redux';
import { errorReportingEnabled, reportBug } from '@signal/utils/errorReporting';
import ErrorScreen from '@signal/components/ErrorScreen';
import Portal from '@signal/components/Portal';
import { goBack } from 'connected-react-router';
import { Wrapper } from './styles';
export function ErrorScreenApi(_ref) {
  var message = _ref.message,
      retry = _ref.retry,
      goBack = _ref.goBack;
  var bugReportAction = errorReportingEnabled() ? {
    label: 'Report Bug',
    onClick: reportBug
  } : null;
  return React.createElement(Portal, null, React.createElement(Wrapper, null, React.createElement(ErrorScreen, {
    title: "Something went wrong",
    description: message,
    primaryAction: {
      label: 'Retry',
      onClick: retry
    },
    secondaryAction: [{
      label: 'Go back',
      onClick: goBack
    }, bugReportAction]
  })));
}
export default connect(null, {
  goBack: goBack
})(ErrorScreenApi);