import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  /* Constrain width to app's root div */
  max-width: 100%;
`

export const Content = styled.div`
  width: 100%;
`
