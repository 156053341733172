function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import noop from 'lodash/noop';
import isProduction from '../env/isProduction';
import isServer from '../env/isServer';
import checkStore from '../checkStore';
import { SAGA_MODES } from './constants';
import { validateKey, validateDescriptor } from './validate';
/**
 * A saga descriptor that contains the root saga to run and the mode to run it in.
 * @global
 * @typedef {Object} SagaDescriptor
 * @property {function} saga - A root saga that will be injected
 * @property {SAGA_MODE} [mode={SAGA_MODES.RESTART}] - Mode with which to run saga.
 */

/**
 * Creates a function that allows a user to inject a saga into the provided
 * redux store.
 *
 * @param {Store} store - A redux store to inject sagas into
 * @param {object} isValid - A flag to specify that the store has already been
 * check for validity.
 */

export function injectSagaFactory(store, isValid) {
  /**
   * @NOTE - We must prevent sagas from running on the server. Instead we return
   * a dummy `injectSaga` function that returns a Promise that never resolves;
   * that way, loaders never finish and the skeleton loader UI is the only thing
   * that renders for each page.
   */
  if (isServer()) return function () {
    return new Promise(noop);
  };
  /**
   * Dynamically injects a saga, passes component's props as saga arguments
   *
   * @param {string} key - A key of the saga
   * @param {SagaDescriptor} descriptor - A descriptor for a saga
   * @param {...*} [args] - Arguments to pass through to saga that is run
   * @returns {Promise} - Promise that resolves when saga is done
   */

  return function injectSaga(key) {
    var descriptor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!isValid) checkStore(store);

    var newDescriptor = _objectSpread({}, descriptor, {
      mode: descriptor.mode || SAGA_MODES.RESTART
    });

    var saga = newDescriptor.saga;
    validateKey(key);
    validateDescriptor(newDescriptor);
    var hasSaga = Reflect.has(store.injectedSagas, key);

    if (!isProduction()) {
      var oldDescriptor = store.injectedSagas[key]; // enable hot reloading of daemon and once-till-unmount sagas

      if (hasSaga && oldDescriptor.saga !== saga) {
        oldDescriptor.task.cancel();
        hasSaga = false;
      }
    }

    if (!hasSaga) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      store.injectedSagas[key] = _objectSpread({}, newDescriptor, {
        task: store.runSaga.apply(store, [saga].concat(args))
      });
    }

    return store.injectedSagas[key].task.toPromise();
  };
}
/**
 * Creates a function that allows a user to eject a saga from the provided
 * redux store.
 *
 * @param {Store} store - A redux store to eject sagas from
 * @param {object} isValid - A flag to specify that the store has already been
 * check for validity.
 */

export function ejectSagaFactory(store, isValid) {
  /**
   * @NOTE - We prevent sagas from running on the server, so we replace `ejectSaga`
   * with a `noop` function.
   */
  if (isServer()) return noop;
  /**
   * Dynamically ejects a saga
   *
   * @param {string} key - A key of the saga
   */

  return function ejectSaga(key) {
    if (!isValid) checkStore(store);
    validateKey(key);

    if (Reflect.has(store.injectedSagas, key)) {
      var descriptor = store.injectedSagas[key];

      if (descriptor.mode !== SAGA_MODES.DAEMON) {
        descriptor.task.cancel();

        if (descriptor.mode === SAGA_MODES.ONCE) {
          // Clean up in production; in development we need `descriptor.saga` for hot reloading
          if (isProduction()) {
            // Need some value to be able to detect `ONCE` sagas in `injectSaga`
            store.injectedSagas[key] = 'done';
          }
        } else {
          // Delete from `injectedSagas` so it can be run again
          Reflect.deleteProperty(store.injectedSagas, key);
        }
      }
    }
  };
}