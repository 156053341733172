function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  text-decoration: underline;\n  justify-self: center;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  color: ", ";\n  margin: ", "px 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  color: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  justify-self: center;\n  margin: ", "px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-content: center;\n  align-items: center;\n  grid-gap: ", "px;\n  width: 80%;\n  max-width: 300px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
import Button from '../Button';
import { getSpacingSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.palette.grey[100];
});
export var Grid = styled.div(_templateObject2(), getSpacingSelector(2));
export var ErrorImage = styled.img(_templateObject3(), getSpacingSelector(3));
export var Title = styled(Typography).attrs({
  variant: 'h6'
})(_templateObject4(), function (props) {
  return props.theme.palette.grey[500];
});
export var Description = styled(Typography).attrs({
  variant: 'subtitle1'
})(_templateObject5(), function (props) {
  return props.theme.palette.grey[500];
}, getSpacingSelector());
export var PrimaryButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
  size: 'large'
})(_templateObject6());
export var SecondaryButton = styled(Button).attrs({
  size: 'small'
})(_templateObject7(), function (props) {
  return props.theme.palette.grey[500];
});