import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactJssProvider from 'react-jss/lib/JssProvider';
import { Consumer as JssConsumer } from './contexts/Jss';
import { Consumer as StoreConsumer } from './contexts/Store';
export default function AppProvider(_ref) {
  var children = _ref.children;
  return React.createElement(JssConsumer, null, function (_ref2) {
    var jss = _ref2.jss,
        generateClassName = _ref2.generateClassName;
    return React.createElement(StoreConsumer, null, function (store) {
      return React.createElement(ReactJssProvider, {
        jss: jss,
        generateClassName: generateClassName
      }, React.createElement(Provider, {
        store: store
      }, React.createElement(ConnectedRouter, {
        history: store.history
      }, children)));
    });
  });
}