function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createSelector } from 'reselect';
import get from 'lodash/get';
import { typographySelector, getColorPrimarySelector, getConstantSelector, getSignalFontVariantName, getSpacingSelector } from '@signal/theme';

var getFontVariantSelector = function getFontVariantSelector(variant) {
  return createSelector(typographySelector, function (typography) {
    return get(typography, getSignalFontVariantName(variant));
  });
};

var body4VariantSelector = getFontVariantSelector('body4');
var body1VariantSelector = getFontVariantSelector('body1');
var colorPrimarySelector = getColorPrimarySelector();
var colorPrimaryDarkSelector = getColorPrimarySelector('dark');
var buttonMinWidthSelector = getConstantSelector('BUTTON_MIN_WIDTH');
var buttonMinHeightSelector = getConstantSelector('BUTTON_MIN_HEIGHT');
var buttonSmallMinWidthSelector = getConstantSelector('BUTTON_SMALL_MIN_WIDTH');
var buttonSmallMinHeightSelector = getConstantSelector('BUTTON_SMALL_MIN_HEIGHT');
var buttonLargeMinWidthSelector = getConstantSelector('BUTTON_LARGE_MIN_WIDTH');
var buttonLargeMinHeightSelector = getConstantSelector('BUTTON_LARGE_MIN_HEIGHT');
var horizontalSpacingSelector = getSpacingSelector(2);
var verticalSpacingSelector = getSpacingSelector();
export default (function () {
  var ruleOverrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
  return function (theme) {
    return _objectSpread({
      root: _objectSpread({
        textTransform: 'none',
        minWidth: buttonMinWidthSelector(theme),
        minHeight: buttonMinHeightSelector(theme),
        padding: "".concat(verticalSpacingSelector(theme), "px ").concat(horizontalSpacingSelector(theme), "px")
      }, body4VariantSelector(theme)),
      sizeSmall: {
        minWidth: buttonSmallMinWidthSelector(theme),
        minHeight: buttonSmallMinHeightSelector(theme)
      },
      sizeLarge: _objectSpread({
        minWidth: buttonLargeMinWidthSelector(theme),
        minHeight: buttonLargeMinHeightSelector(theme)
      }, body1VariantSelector(theme)),
      contained: {
        boxShadow: 'none',
        '&$focusVisible': {
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none'
        },
        '&$disabled': {
          boxShadow: 'none'
        }
      },
      containedPrimary: {
        backgroundColor: colorPrimarySelector(theme),
        '&:hover': {
          backgroundColor: colorPrimaryDarkSelector(theme),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: colorPrimarySelector(theme)
          }
        }
      },

      /* Styles applied to the ButtonBase root element if the button is keyboard focused. */
      focusVisible: {},

      /* Styles applied to the root element if `disabled={true}`. */
      disabled: {}
    }, ruleOverrides(theme));
  };
});