import { put, call } from 'redux-saga/effects'
import takeLeading from '@signal/utils/takeLeading'
import {
  authRequest,
  authSuccess,
  authFailure,
  logout
} from '@signal/core/auth'
import { PC_AUTH_COOKIE } from './constants'

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return
}

export const getToken = () => false

const redirectToAuthService = redirectUrl => {
  window.location.assign(
    `https://apix.purecars.com/sheriff/external/v1/user/auth?redirect=${redirectUrl}`
  )
}

export function* handleAuthFailure(getRedirectUrl) {
  const redirectUrl = yield call(getRedirectUrl)
  redirectToAuthService(redirectUrl)
}

export function* handleLogout(getRedirectUrl) {
  const redirectUrl = yield call(getRedirectUrl)
  window.location.assign(
    `https://apix.purecars.com/sheriff/external/v1/user/logout?redirect=${redirectUrl}`
  )
}

export function* loadSaga() {
  yield put(authRequest())
  const pc_auth = getCookie(PC_AUTH_COOKIE)
  if (pc_auth) {
    yield put(authSuccess())
  } else {
    yield call(handleAuthFailure, () => window.location.origin)
  }
}

export function* listenSaga() {
  yield takeLeading(
    authFailure,
    handleAuthFailure,
    () => window.location.origin
  )
  yield takeLeading(logout, handleLogout, () => window.location.origin)
}
