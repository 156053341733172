import isObject from 'lodash/isObject';
import { REFRESH_WINDOW } from '../constants';
export default function isTokenValid(tokenDetails) {
  if (!isObject(tokenDetails)) return false;
  var expires = tokenDetails.expires; // If token expiration time is not provided, it's invalid

  if (expires == null) return false; // Adjust for the refresh window before the token expires

  var refreshTime = expires - REFRESH_WINDOW; // Ensure that current time is before the token's expiration time

  return Date.now() < refreshTime;
}