function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import mapValues from 'lodash/mapValues';
/**
 * A function (or object) used by the `createTypography` theme middleware to
 * override the theme's `typography` configuration object.
 *
 * If it's a function, `createTypography` calls the function with a
 * {@link ThemeOverrides} object and uses the returned object as the override
 * for the theme's `typography` configuration object.
 *
 * If it's an object, `createTypography` uses it as-is for the override for the
 * theme's `typography` configuration object.
 * @typedef {Function|Object} TypographyThemeOverrideCreator
 */

/**
 * Gets the base styles to use for all font variants.
 * @param {ThemeOverrides} overrides
 */

var getBaseFontStyles = function getBaseFontStyles(_ref) {
  var FONT_FAMILY = _ref.constants.FONT_FAMILY;
  return {
    fontFamily: FONT_FAMILY,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal'
  };
};
/**
 * Creates a {@link ThemeOverrideTypography} function that extends each of the
 * provided font variants with the base font styles.
 * @param {ThemeOverrideTypography} variants
 * @returns {ThemeOverrideTypography}
 */


export var createTypographyOverrides = function createTypographyOverrides(variants) {
  return function (themeOverrides) {
    var baseFontStyles = getBaseFontStyles(themeOverrides); // Apply base font styles to every variant

    return mapValues(variants, function (value) {
      return _objectSpread({}, baseFontStyles, value);
    });
  };
};
/**
 * Defines the default font variants available all signal apps.
 * @type {ThemeOverrideTypography}
 */

export default createTypographyOverrides({
  /**
   * These fonts are custom font defitions that will be consumed by the
   * `@signal/components/Typography` component.
   */
  h1: {
    fontSize: 54,
    lineHeight: 68,
    fontWeight: 300
  },
  h2: {
    fontSize: 28,
    lineHeight: 35,
    fontWeight: 'bold'
  },
  h3: {
    fontSize: 24,
    lineHeight: 34
  },
  h4: {
    fontSize: 20,
    lineHeight: 24
  },
  h5: {
    fontSize: 18,
    lineHeight: 23
  },
  h6: {
    fontSize: 18,
    lineHeight: 20
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 20
  },
  subtitle2: {
    fontSize: 16,
    lineHeight: 24
  },
  body1: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 600
  },
  body2: {
    fontSize: 14,
    lineHeight: 20
  },
  body3: {
    fontSize: 14,
    lineHeight: 18
  },
  body4: {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: 600
  },
  caption: {
    fontSize: 12,
    lineHeight: 16
  },
  button: {
    fontSize: 14,
    lineHeight: 25,
    fontWeight: 600,
    textTransform: 'uppercase'
  }
});