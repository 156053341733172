/**
 * Taken from https://github.com/mui-org/material-ui/blob/9410e6edaf5ecc9ae0049e4a14f29b66c42fd910/packages/material-ui/src/styles/createTypography.js
 * Tell Material-UI what's the font-size on the html element.
 * 16px is the default font-size used by browsers.
 */
export var HTML_FONT_SIZE = 16;
/**
 * The default font size of the Material Specification.
 */

export var FONT_SIZE = 14;
export var FONT_FAMILY = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';