import React, { memo } from 'react';
import ModalManagerConsumer from './Consumer';
var Root = memo(function () {
  return React.createElement(ModalManagerConsumer, null, function (_ref) {
    var Modal = _ref.modal,
        showModal = _ref.showModal,
        dismissModal = _ref.dismissModal;
    return Modal ? React.createElement(Modal, {
      showModal: showModal,
      dismissModal: dismissModal
    }) : null;
  });
});
Root.displayName = 'ModalManagerRoot';
export default Root;