var _createReducer;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import storage from 'local-storage-fallback';
import { authRequest, authSuccess, authFailure, setActiveAccount } from './actions';
import { LOCAL_STORAGE_ACTIVE_ACCOUNT_KEY } from './constants';
var activeAccount = null;

try {
  activeAccount = JSON.parse(storage.getItem(LOCAL_STORAGE_ACTIVE_ACCOUNT_KEY));
} catch (err) {
  console.warn('Unable to get active account from localStorage! Account object was malformed.');
}

var initialState = {
  token: null,
  activeAccount: activeAccount,
  expires: null,
  authenticating: false,
  isInitialized: false,
  error: null
};
export var authReducer = createReducer((_createReducer = {}, _defineProperty(_createReducer, authRequest, function (state) {
  return _objectSpread({}, state, {
    authenticating: true,
    isInitialized: true
  });
}), _defineProperty(_createReducer, authSuccess, function (state, payload) {
  return _objectSpread({}, state, payload, {
    authenticating: false
  });
}), _defineProperty(_createReducer, authFailure, function (state, payload) {
  return _objectSpread({}, state, {
    token: null,
    expires: null,
    error: payload,
    authenticating: false
  });
}), _defineProperty(_createReducer, setActiveAccount, function (state, newActiveAccount) {
  return _objectSpread({}, state, {
    activeAccount: newActiveAccount
  });
}), _createReducer), initialState);
export default authReducer;