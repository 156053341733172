import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { createRoutes } from '@signal/router';
import { activeAccountIdSelector } from '@signal/core/auth';
import AnimatedSwitch from '../AnimatedSwitch';
export function SignalRouter(_ref) {
  var activeAccount = _ref.activeAccount,
      location = _ref.location,
      config = _ref.config,
      animateRoutes = _ref.animateRoutes;
  var animationOverrides = config.animationOverrides,
      navigationStacks = config.navigationStacks,
      routeKeyGenerator = config.routeKeyGenerator,
      routeDefinitions = config.routeDefinitions;
  var childProps = {
    activeAccount: activeAccount
  };
  return React.createElement(AnimatedSwitch, {
    location: location,
    stacks: navigationStacks,
    overrides: animationOverrides,
    routeKeyGenerator: routeKeyGenerator,
    animate: animateRoutes
  }, createRoutes(routeDefinitions, childProps));
}
var mapStateToProps = createStructuredSelector({
  activeAccount: activeAccountIdSelector
});
export default compose(withRouter, connect(mapStateToProps))(SignalRouter);