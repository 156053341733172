import common from '@material-ui/core/colors/common';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
export var COLOR_COMMON_BLACK = common.black;
export var COLOR_COMMON_WHITE = common.white;
export var COLOR_PRIMARY_LIGHT = lightBlue[500];
export var COLOR_PRIMARY = lightBlue[600];
export var COLOR_PRIMARY_DARK = lightBlue[700];
export var COLOR_PRIMARY_DEEP = lightBlue[900];
export var COLOR_SECONDARY_LIGHT = lightBlue[50];
export var COLOR_SECONDARY = lightBlue[100];
export var COLOR_SECONDARY_DARK = lightBlue[200];
export var COLOR_SECONDARY_DEEP = lightBlue[300];
export var COLOR_GREY_ACCENT = grey[50];
export var COLOR_GREY_LIGHT = grey[300];
export var COLOR_GREY = grey[500];
export var COLOR_GREY_DARK = grey[700];
export var COLOR_GREY_DEEP = grey[900];
export var COLOR_SUCCESS_LIGHT = green[300];
export var COLOR_SUCCESS = green[500];
export var COLOR_SUCCESS_DARK = green[700];
export var COLOR_ERROR_LIGHT = red[300];
export var COLOR_ERROR = red[400];
export var COLOR_ERROR_DARK = red[700];
export var COLOR_WARN_LIGHT = yellow[100];
export var COLOR_WARN = yellow[600];