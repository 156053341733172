function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

export var ERROR_NAME = 'MissingDataError';
export var MissingDataError = function MissingDataError(dataType, componentName) {
  _classCallCheck(this, MissingDataError);

  var errorSource = componentName ? "`".concat(componentName, "`") : 'a child component';
  var message = "".concat(dataType, " data required by ").concat(errorSource, " is missing or invalid");
  this.message = message;
  this.dataType = dataType;
  this.component = componentName; // capture the stack trace and store it as `this.stack`

  if ('captureStackTrace' in Error) {
    // `Error.captureStackTrace()` can be used for modern browsers
    Error.captureStackTrace(this, MissingDataError);
  } else {
    // capture stack trace in older browsers
    var errTrace = new Error(message);
    errTrace.name = ERROR_NAME;
    this.stack = errTrace.stack;
  }
}; // Back-door inheritance

Object.setPrototypeOf(MissingDataError, Error.prototype);
MissingDataError.prototype.__proto__ = Error.prototype;
MissingDataError.prototype.constructor = MissingDataError;
MissingDataError.prototype.name = ERROR_NAME;
export default MissingDataError;