import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import SignalProvider from '@signal/core/signal/Provider'
import injectSaga, { SAGA_MODES } from '@signal/hoc/injectSaga'
import { activeAccountIdSelector } from '@signal/core/auth'
import { listen as apiListen } from '@signal/core/api'
import { getToken } from './auth'

const mapStateToProps = createStructuredSelector({
  accountId: activeAccountIdSelector
})

export default compose(
  connect(mapStateToProps),
  injectSaga(
    {
      key: 'api',
      saga: apiListen,
      mode: SAGA_MODES.DAEMON
    },
    getToken
  )
)(SignalProvider)
