function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createSelector } from 'reselect';
import { getLocation } from 'connected-react-router';
import queryString from 'query-string';
import get from 'lodash/get';
import select from '@signal/utils/select';
import { ACCOUNT, PROFILE, ACCOUNTS, LOGOUT, CHATS, CHAT_DETAIL, CHAT_NEW, INSIGHTS, INSIGHT_DETAIL, BOOKMARKS, MANAGE_SPEND, PERFORMANCE_TABS, PERFORMANCE, PERFORMANCE_OVERVIEW, PERFORMANCE_CHANNELS, PERFORMANCE_CHANNELS_DETAIL, PERFORMANCE_CHANNELS_VIN, PERFORMANCE_INVENTORY, PERFORMANCE_INVENTORY_DETAIL, PERFORMANCE_INVENTORY_DETAIL_VIN, PERFORMANCE_INVENTORY_VIN, PERFORMANCE_SHOPPERS, PERFORMANCE_SHOPPERS_BUCKET, ADS, AD_DETAIL, AD_STATS, AD_CREATE, AUDIENCES, AUDIENCE_DETAIL, AUDIENCE_CREATE, SHOPPER_DETAIL } from '../routes';
import { activeAccountIdSelector } from '../auth';
import { REDUCER_KEY } from './constants';
export var locationSelector = getLocation;
export var searchSelector = createSelector(locationSelector, select('search'));
export var pathnameSelector = createSelector(locationSelector, select('pathname'));
export var searchObjSelector = createSelector(searchSelector, function (search) {
  return queryString.parse(search);
});
export var createRouteParamsSelector = function createRouteParamsSelector(route) {
  return createSelector(pathnameSelector, function (pathname) {
    var match = route.match(pathname, {
      exact: true
    });
    return get(match, 'params', {});
  });
};
export function getPathForRoute(route, props) {
  return Object.values(route.params).every(function (param) {
    return !!props[param];
  }) ? route.buildPath(props) : route.path;
}
export var createSearchParamSelector = function createSearchParamSelector(param, defaultValue) {
  return createSelector(searchObjSelector, select(param, defaultValue));
};
export var logoutPathSelector = function logoutPathSelector() {
  return LOGOUT.path;
};
export var profilePathSelector = function profilePathSelector() {
  return PROFILE.path;
};
export var accountsPathSelector = function accountsPathSelector() {
  return ACCOUNTS.path;
};
export var getPerformanceOverviewPathByAccountId = function getPerformanceOverviewPathByAccountId(accountId) {
  return getPathForRoute(PERFORMANCE, {
    accountId: accountId
  });
};
export var accountPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(ACCOUNT, {
    accountId: accountId
  });
});
export var insightsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(INSIGHTS, {
    accountId: accountId
  });
});
export var insightDetailPathSelector = createSelector( // eslint-disable-next-line no-unused-vars
function (state, params) {
  return params;
}, activeAccountIdSelector, function (params, accountId) {
  return getPathForRoute(INSIGHT_DETAIL, _objectSpread({
    accountId: accountId
  }, params));
});
export var bookmarksPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(BOOKMARKS, {
    accountId: accountId
  });
});
export var manageSpendPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(MANAGE_SPEND, {
    accountId: accountId
  });
});
export var chatsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(CHATS, {
    accountId: accountId
  });
});
export var chatPathSelector = createSelector( // eslint-disable-next-line no-unused-vars
function (state, props) {
  return get(props, 'chatId');
}, activeAccountIdSelector, function (chatId, accountId) {
  return getPathForRoute(CHAT_DETAIL, {
    chatId: chatId,
    accountId: accountId
  });
});
export var chatsNewPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(CHAT_NEW, {
    accountId: accountId
  });
});
export var signalRouterSelector = function signalRouterSelector(state) {
  return get(state, REDUCER_KEY);
};
export var activePerformanceTabSelector = createSelector(signalRouterSelector, select('activePerformanceTab', PERFORMANCE_TABS[0].key));
export var performancePathSelector = createSelector(activeAccountIdSelector, activePerformanceTabSelector, function (accountId, activeTab) {
  return getPathForRoute(PERFORMANCE, {
    accountId: accountId,
    activeTab: activeTab
  });
});
export var performanceTabPathSelector = createSelector(activeAccountIdSelector, activePerformanceTabSelector, function (accountId, activeTab) {
  return getPathForRoute(PERFORMANCE_OVERVIEW, {
    accountId: accountId,
    activeTab: activeTab
  });
});
export var performanceChannelsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_CHANNELS, {
    accountId: accountId
  });
});
export var performanceChannelsDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_CHANNELS_DETAIL, {
    accountId: accountId
  });
}); // TODO improve dryness of partial selectors

export var partialChannelsVinSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (vin) {
    return getPathForRoute(PERFORMANCE_CHANNELS_VIN, {
      accountId: accountId,
      vin: vin
    });
  };
}); // TODO improve dryness of partial selectors

export var partialInventoryVinModalSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (vin) {
    return getPathForRoute(PERFORMANCE_INVENTORY_DETAIL_VIN, {
      accountId: accountId,
      vin: vin
    });
  };
}); // TODO improve dryness of partial selectors

export var partialInventoryVinSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (vin) {
    return getPathForRoute(PERFORMANCE_INVENTORY_VIN, {
      accountId: accountId,
      vin: vin
    });
  };
});
export var performanceInventoryPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_INVENTORY, {
    accountId: accountId
  });
});
export var performanceInventoryDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_INVENTORY_DETAIL, {
    accountId: accountId
  });
});
export var performanceShoppersPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_SHOPPERS, {
    accountId: accountId
  });
});
export var partialPerformanceShopperPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (other) {
    return getPathForRoute(SHOPPER_DETAIL, _objectSpread({
      accountId: accountId
    }, other));
  };
});
export var partialPerformanceShoppersBucketPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (shopperBucket) {
    return getPathForRoute(PERFORMANCE_SHOPPERS_BUCKET, {
      accountId: accountId,
      shopperBucket: shopperBucket
    });
  };
});
export var adsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(ADS, {
    accountId: accountId
  });
});
export var partialAdDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (adId) {
    return getPathForRoute(AD_DETAIL, {
      accountId: accountId,
      adId: adId
    });
  };
});
export var partialAdStatsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (adId) {
    return getPathForRoute(AD_STATS, {
      accountId: accountId,
      adId: adId
    });
  };
});
export var adCreatePathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(AD_CREATE, {
    accountId: accountId
  });
});
export var audiencesPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(AUDIENCES, {
    accountId: accountId
  });
});
export var partialAudienceDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (audienceId) {
    return getPathForRoute(AUDIENCE_DETAIL, {
      accountId: accountId,
      audienceId: audienceId
    });
  };
});
export var audienceCreatePathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(AUDIENCE_CREATE, {
    accountId: accountId
  });
});