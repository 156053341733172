function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
import invariant from 'invariant';
import { formatTypography, formatMuiTypography } from './utils';
/**
 * Middleware that handles `typography` and `muiTypography` key overrides.
 * @type {ThemeMiddleware}
 */

export function typographyMiddleware(baseOverrides, customOverrides) {
  var _baseOverrides$typogr = baseOverrides.typography,
      baseTypography = _baseOverrides$typogr === void 0 ? {} : _baseOverrides$typogr,
      _baseOverrides$muiTyp = baseOverrides.muiTypography,
      baseMuiTypography = _baseOverrides$muiTyp === void 0 ? {} : _baseOverrides$muiTyp,
      restBaseOverrides = _objectWithoutProperties(baseOverrides, ["typography", "muiTypography"]);

  var _customOverrides$typo = customOverrides.typography,
      inputTypography = _customOverrides$typo === void 0 ? {} : _customOverrides$typo,
      _customOverrides$muiT = customOverrides.muiTypography,
      inputMuiTypography = _customOverrides$muiT === void 0 ? {} : _customOverrides$muiT,
      restInputOverrides = _objectWithoutProperties(customOverrides, ["typography", "muiTypography"]); // Merge everything except for typography overrides


  var overrides = merge({}, restBaseOverrides, restInputOverrides);
  var _overrides$constants = overrides.constants,
      HTML_FONT_SIZE = _overrides$constants.HTML_FONT_SIZE,
      FONT_SIZE = _overrides$constants.FONT_SIZE,
      FONT_FAMILY = _overrides$constants.FONT_FAMILY;

  function pxToRem(value) {
    var coef = FONT_SIZE / 14;
    return "".concat(value / HTML_FONT_SIZE * coef, "rem");
  }

  invariant(isNumber(HTML_FONT_SIZE) && isNumber(FONT_SIZE), 'Function `createTypography` expected both `HTML_FONT_SIZE` and `FONT_SIZE` to be numbers.'); // For both standard typography overrides and muiTypography overrides map them
  // with their respective format function and merge base with input.

  var typography = merge.apply(void 0, [{}].concat(_toConsumableArray([baseTypography, inputTypography].map(function (typography) {
    return formatTypography(typography, overrides, pxToRem);
  }))));
  var muiTypography = merge.apply(void 0, [{}].concat(_toConsumableArray([baseMuiTypography, inputMuiTypography].map(function (typography) {
    return formatMuiTypography(typography, overrides);
  }))));
  return _objectSpread({}, customOverrides, {
    typography: _objectSpread({
      fontSize: FONT_SIZE,
      htmlFontSize: HTML_FONT_SIZE,
      fontFamily: FONT_FAMILY
    }, typography, muiTypography)
  });
}
export default typographyMiddleware;