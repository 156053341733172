function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createAction } from 'redux-act';
import { LOCAL_SET_SUMMARY_EXPANDED, LOCAL_SET_RECENTLY_VIEWED_ACCOUNTS, LOCAL_BEGIN_ONBOARDING_TOUR, LOCAL_DISMISS_ONBOARDING_TOUR } from './constants';
/**
 * Actions
 */

export var setSummaryExpanded = createAction(LOCAL_SET_SUMMARY_EXPANDED, function (summaryType, expanded) {
  return _defineProperty({}, summaryType, expanded);
});
export var setDateRange = createAction('SET_DATE_RANGE');
export var setRecentlyViewedAccount = createAction(LOCAL_SET_RECENTLY_VIEWED_ACCOUNTS, function (_ref2) {
  var accountId = _ref2.accountId;
  return accountId;
} // Don't need to do this it just clarifies the params of the action
);
export var beginOnboardingTour = createAction(LOCAL_BEGIN_ONBOARDING_TOUR);
export var dismissOnboardingTour = createAction(LOCAL_DISMISS_ONBOARDING_TOUR);