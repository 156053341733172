import React from 'react';
import { render } from 'react-snapshot';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import isSnapshot from '@signal/utils/env/isSnapshot';
import { register as registerServiceWorker } from '@signal/utils/serviceWorker';
import { serviceWorkerActivationListener } from './app';
import { Provider as JssProvider } from './contexts/Jss';
import { Provider as StoreProvider } from './contexts/Store';
import configureStore from './configureStore';
import AppProvider from './AppProvider';
/**
 * Before the app renders, we need to check if we are hydrating an HTML page
 * generated by `react-snapshot`. If we are, we need to remove all jss generated
 * during the static build.
 *
 * If the build is a `react-snapshot` build, we need to monkey-patch the
 * `CSSStyleSheet.insertRule()` function used by `styled-components` in order to
 * keep track of the styles being inserted, so that we can add them as style tags
 * after render.
 *
 * @return {Object} The style artifacts of the build
 */

function beforeRender() {
  var ssrStyles = document.querySelectorAll('style[data-jss]');
  var cssInserts = [];

  if (isSnapshot()) {
    var insertRuleOriginal = CSSStyleSheet.prototype.insertRule;

    CSSStyleSheet.prototype.insertRule = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      cssInserts.push(args);
      return insertRuleOriginal.apply(this, args);
    };
  }

  return {
    ssrStyles: ssrStyles,
    cssInserts: cssInserts
  };
}
/**
 * After the app renders, we first need to register the service worker. We then
 * check to see if there were any SSR styles that need to be removed (since the
 * client would have rendered it's own jss style tags by now).
 *
 * If any CSS rules were inserted during `react-snapshot` build, we need to add
 * them to a style tag and append it to the document head.
 *
 * After that, we should remove any script tags added by webpack during the
 * `react-snapshot` build. This would be any dynamic chunks that were loaded. The
 * reason we have to do this is because of the order they are injected by webpack.
 * They are injected to the end of the head, whereas the main js chunk is loaded
 * from the bottom of the body tag. This results in an error that states
 * `"webpackJsonp" is not defined` since that function is instantiated in the main
 * chunk and used by each dynamic chunk.
 */


function afterRender(_ref) {
  var ssrStyles = _ref.ssrStyles,
      cssInserts = _ref.cssInserts;

  if (ssrStyles.length) {
    ssrStyles.forEach(function (ssrStyle) {
      return ssrStyle.remove();
    });
  }

  if (cssInserts.length) {
    var css = cssInserts.reverse().map(function (i) {
      return i[0];
    }).join('');
    var styles = document.createElement('style');
    styles.appendChild(document.createTextNode(css));
    styles.setAttribute('data-styled-components', '');
    document.head.appendChild(styles);
  }

  var chunks = document.querySelectorAll('script[charset]');

  if (chunks.length) {
    chunks.forEach(function (chunk) {
      return chunk.remove();
    });
  }
}
/**
 * Wraps React's render with some logic to handle static rendering.
 */


export default function bootstrap(component, renderTarget) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var cssArtifacts = beforeRender();
  var store = configureStore(options.createHistory);
  var generateClassName = createGenerateClassName();
  var jss = create(jssPreset());
  jss.options.insertionPoint = document.getElementById('jss-insertion-point');
  var jssOptions = {
    jss: jss,
    generateClassName: generateClassName
  };
  render(React.createElement(JssProvider, {
    value: jssOptions
  }, React.createElement(StoreProvider, {
    value: store
  }, React.createElement(AppProvider, null, component))), renderTarget); // the service worker is disabled in the native app

  if (!options.disableSW) {
    /**
     * When a new app version is released and the browser detects a new service
     * worker file, it is downloaded, installed, and then put into a waiting
     * state. By default, the new service worker does not become active until
     * the existing one terminates; this only happens when all of the browser
     * tabs it controls are closed, OR the user navigates away to a different
     * domain.
     *
     * In order to allow a user to circumvent the default behavior and get the
     * new app version right away, the service worker registration function
     * accepts a listener function that will be called when an updated service
     * worker is available. This allows us to prompt the user asking if they
     * want to go ahead and activate the updated service worker immediately.
     */
    var onUpdatedServiceWorker = function onUpdatedServiceWorker(activateCallback) {
      return store.runSaga(serviceWorkerActivationListener, activateCallback);
    }; // pass updated service worker handler to service worker registration


    registerServiceWorker(onUpdatedServiceWorker);
  }

  afterRender(cssArtifacts);
}