import { AUDIENCE_MICROSERVICE_URL, ANALYTICS_MAPPING_MICROSERVICE_URL, NATIVE_MICROSERVICE_URL } from './constants';
var microservices = [AUDIENCE_MICROSERVICE_URL, ANALYTICS_MAPPING_MICROSERVICE_URL, NATIVE_MICROSERVICE_URL];
export function isSignalMicroserviceRequest(url) {
  return microservices.some(function (microserviceUrl) {
    return url.startsWith(microserviceUrl);
  });
}
export function isExternalRequest(url) {
  return url.startsWith('http') && !isSignalMicroserviceRequest(url);
}
export function withAudienceMicroserviceUrl(path) {
  return "".concat(AUDIENCE_MICROSERVICE_URL).concat(path);
}
export function withNativeMicroserviceUrl(path) {
  return [NATIVE_MICROSERVICE_URL, path].join('');
}