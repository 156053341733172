var _SUMMARY_EXPANDED, _initialState, _createReducer;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import format from 'date-fns/format';
import { SUMMARY_EXPANDED, SUMMARY_TYPE_CHANNELS, SUMMARY_TYPE_INVENTORY, SUMMARY_TYPE_SHOPPERS, DATE_RANGE, DATE_RANGE_PRESETS, DATE_FORMAT, RECENTLY_VIEWED_ACCOUNTS_KEY, SHOW_ONBOARDING_TOUR } from './constants';
import { setSummaryExpanded, setDateRange, setRecentlyViewedAccount, beginOnboardingTour, dismissOnboardingTour } from './actions';
export var initialState = (_initialState = {}, _defineProperty(_initialState, SUMMARY_EXPANDED, (_SUMMARY_EXPANDED = {}, _defineProperty(_SUMMARY_EXPANDED, SUMMARY_TYPE_CHANNELS, true), _defineProperty(_SUMMARY_EXPANDED, SUMMARY_TYPE_INVENTORY, true), _defineProperty(_SUMMARY_EXPANDED, SUMMARY_TYPE_SHOPPERS, true), _SUMMARY_EXPANDED)), _defineProperty(_initialState, DATE_RANGE, DATE_RANGE_PRESETS[0]), _defineProperty(_initialState, RECENTLY_VIEWED_ACCOUNTS_KEY, []), _defineProperty(_initialState, SHOW_ONBOARDING_TOUR, true), _initialState);
export default createReducer((_createReducer = {}, _defineProperty(_createReducer, setSummaryExpanded, function (state, payload) {
  return _objectSpread({}, state, _defineProperty({}, SUMMARY_EXPANDED, _objectSpread({}, state[SUMMARY_EXPANDED], payload)));
}), _defineProperty(_createReducer, setDateRange, function (state, _ref) {
  var startDate = _ref.startDate,
      endDate = _ref.endDate;
  return _objectSpread({}, state, _defineProperty({}, DATE_RANGE, {
    start: startDate ? format(startDate, DATE_FORMAT) : null,
    end: endDate ? format(endDate, DATE_FORMAT) : null
  }));
}), _defineProperty(_createReducer, setRecentlyViewedAccount, function (state, accountId) {
  var prevAccountList = get(state, RECENTLY_VIEWED_ACCOUNTS_KEY, []);
  var accountList = [accountId].concat(_toConsumableArray(prevAccountList));
  return _objectSpread({}, state, _defineProperty({}, RECENTLY_VIEWED_ACCOUNTS_KEY, uniq(accountList)));
}), _defineProperty(_createReducer, beginOnboardingTour, function (state) {
  return _objectSpread({}, state, _defineProperty({}, SHOW_ONBOARDING_TOUR, true));
}), _defineProperty(_createReducer, dismissOnboardingTour, function (state) {
  return _objectSpread({}, state, _defineProperty({}, SHOW_ONBOARDING_TOUR, false));
}), _createReducer), initialState);