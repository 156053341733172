var _createReducer;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import without from 'lodash/without';
import { showModal, dismissModal, setActiveModal } from './actions';
export var initialState = {
  requests: [],
  active: null
};
export default createReducer((_createReducer = {}, _defineProperty(_createReducer, showModal, function (state, showModalRequest) {
  return _objectSpread({}, state, {
    requests: state.requests.concat(showModalRequest)
  });
}), _defineProperty(_createReducer, dismissModal, function (_ref) {
  var requests = _ref.requests,
      active = _ref.active;
  return {
    active: null,
    requests: without(requests, active)
  };
}), _defineProperty(_createReducer, setActiveModal, function (state, active) {
  return _objectSpread({}, state, {
    active: active
  });
}), _createReducer), initialState);