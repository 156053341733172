import { COLOR_COMMON_BLACK, COLOR_COMMON_WHITE, COLOR_PRIMARY_LIGHT, COLOR_PRIMARY, COLOR_PRIMARY_DARK, COLOR_PRIMARY_DEEP, COLOR_SECONDARY_LIGHT, COLOR_SECONDARY, COLOR_SECONDARY_DARK, COLOR_SECONDARY_DEEP, COLOR_GREY_ACCENT, COLOR_GREY_LIGHT, COLOR_GREY, COLOR_GREY_DARK, COLOR_GREY_DEEP, COLOR_SUCCESS_LIGHT, COLOR_SUCCESS, COLOR_SUCCESS_DARK, COLOR_ERROR_LIGHT, COLOR_ERROR, COLOR_ERROR_DARK, COLOR_WARN_LIGHT, COLOR_WARN } from '../variables';
/**
 * MUI palette
 */

export var palette = {
  common: {
    black: COLOR_COMMON_BLACK,
    white: COLOR_COMMON_WHITE
  },
  primary: {
    light: COLOR_PRIMARY_LIGHT,
    main: COLOR_PRIMARY,
    dark: COLOR_PRIMARY_DARK,
    deep: COLOR_PRIMARY_DEEP,
    contrastText: COLOR_COMMON_WHITE
  },
  secondary: {
    light: COLOR_SECONDARY_LIGHT,
    main: COLOR_SECONDARY,
    dark: COLOR_SECONDARY_DARK,
    deep: COLOR_SECONDARY_DEEP
  },
  grey: {
    accent: COLOR_GREY_ACCENT,
    light: COLOR_GREY_LIGHT,
    main: COLOR_GREY,
    dark: COLOR_GREY_DARK,
    deep: COLOR_GREY_DEEP
  },
  error: {
    light: COLOR_ERROR_LIGHT,
    main: COLOR_ERROR,
    dark: COLOR_ERROR_DARK
  },
  success: {
    light: COLOR_SUCCESS_LIGHT,
    main: COLOR_SUCCESS,
    dark: COLOR_SUCCESS_DARK
  },
  warn: {
    light: COLOR_WARN_LIGHT,
    main: COLOR_WARN
  },
  text: {
    dark: COLOR_GREY_DEEP,
    primary: COLOR_GREY_DARK,
    secondary: COLOR_GREY
  }
};
export default palette;