import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import renderIf from '@signal/utils/renderIf';
import publicUrl from '@signal/utils/publicUrl';
import { Wrapper, Grid, ErrorImage, Title, Description, PrimaryButton, SecondaryButton } from './styles';

var renderSecondaryActions = function renderSecondaryActions(secondaryAction) {
  var secondaryActions = isArray(secondaryAction) ? secondaryAction : [secondaryAction];
  return secondaryActions.filter(Boolean).map(function (action, i) {
    return React.createElement(SecondaryButton, {
      onClick: action.onClick,
      key: i
    }, action.label);
  });
};

export function ErrorScreen(_ref) {
  var title = _ref.title,
      description = _ref.description,
      primaryAction = _ref.primaryAction,
      secondaryAction = _ref.secondaryAction,
      style = _ref.style,
      className = _ref.className;
  return React.createElement(Wrapper, {
    className: className,
    style: style
  }, React.createElement(Grid, null, React.createElement(ErrorImage, {
    width: 93,
    height: 83,
    src: publicUrl('/images/icon-error.svg'),
    alt: "Something went wrong!"
  }), React.createElement(Title, null, title), React.createElement(Description, null, description), renderIf(primaryAction, function () {
    return React.createElement(PrimaryButton, {
      onClick: primaryAction.onClick
    }, primaryAction.label);
  }), renderIf(secondaryAction, function () {
    return renderSecondaryActions(secondaryAction);
  })));
}
ErrorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  primaryAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  }),
  secondaryAction: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  })), PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  })])
};
export default ErrorScreen;