function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

export var ERROR_NAME = 'FetchError';
export var FetchError = function FetchError(response) {
  _classCallCheck(this, FetchError);

  var message = "request failed with status \"".concat(response.status, " - ").concat(response.statusText, "\"");
  this.message = message;
  this.response = response; // capture the stack trace and store it as `this.stack`

  if ('captureStackTrace' in Error) {
    // `Error.captureStackTrace()` can be used for modern browsers
    Error.captureStackTrace(this, FetchError);
  } else {
    // capture stack trace in older browsers
    var errTrace = new Error(message);
    errTrace.name = ERROR_NAME;
    this.stack = errTrace.stack;
  }
}; // Back-door inheritance

Object.setPrototypeOf(FetchError, Error.prototype);
FetchError.prototype.__proto__ = Error.prototype;
FetchError.prototype.constructor = FetchError;
FetchError.prototype.name = ERROR_NAME;
export default FetchError;