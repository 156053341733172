import Raven from 'raven-js';
import isProduction from './env/isProduction';
export var errorReportingEnabled = function errorReportingEnabled() {
  return Raven.isSetup() && isProduction();
};
export var captureException = function captureException() {
  return errorReportingEnabled() && Raven.captureException.apply(Raven, arguments);
};
export var reportBug = function reportBug() {
  return errorReportingEnabled() && Raven.lastEventId() && Raven.showReportDialog();
};