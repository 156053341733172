var _createReducer;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { userDetailsRequest, userDetailsFailure, userDetailsSuccess, userAccountsRequest, userAccountsFailure, userAccountsSuccess, usersRequest, usersFailure, usersSuccess } from './actions';
export var initialState = {
  me: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    loading: false,
    error: false
  },
  accounts: {
    byId: null,
    list: null,
    loading: false,
    error: false
  },
  users: {
    byId: null,
    list: null,
    loading: false,
    error: false
  }
};
export default createReducer((_createReducer = {}, _defineProperty(_createReducer, userDetailsRequest, function (state) {
  return _objectSpread({}, state, {
    me: _objectSpread({}, initialState.me, {
      loading: true
    })
  });
}), _defineProperty(_createReducer, userDetailsFailure, function (state, error) {
  return _objectSpread({}, state, {
    me: _objectSpread({}, state.me, {
      loading: false,
      error: error
    })
  });
}), _defineProperty(_createReducer, userDetailsSuccess, function (state, data) {
  return _objectSpread({}, state, {
    me: _objectSpread({}, state.me, data, {
      loading: false
    })
  });
}), _defineProperty(_createReducer, userAccountsRequest, function (state) {
  return _objectSpread({}, state, {
    accounts: _objectSpread({}, initialState.accounts, {
      loading: true
    })
  });
}), _defineProperty(_createReducer, userAccountsFailure, function (state, error) {
  return _objectSpread({}, state, {
    accounts: _objectSpread({}, state.accounts, {
      loading: false,
      error: error
    })
  });
}), _defineProperty(_createReducer, userAccountsSuccess, function (state, payload) {
  var list = payload.result,
      accounts = payload.entities.accounts;
  return _objectSpread({}, state, {
    accounts: _objectSpread({}, state.accounts, {
      byId: accounts,
      list: list,
      loading: false
    })
  });
}), _defineProperty(_createReducer, usersRequest, function (state) {
  return _objectSpread({}, state, {
    users: _objectSpread({}, initialState.users, {
      loading: true
    })
  });
}), _defineProperty(_createReducer, usersFailure, function (state, error) {
  return _objectSpread({}, state, {
    users: _objectSpread({}, state.users, {
      loading: false,
      error: error
    })
  });
}), _defineProperty(_createReducer, usersSuccess, function (state, payload) {
  var list = payload.result,
      users = payload.entities.users;
  return _objectSpread({}, state, {
    users: _objectSpread({}, state.users, {
      byId: users,
      list: list,
      loading: false
    })
  });
}), _createReducer), initialState);