function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import React, { memo, useEffect, useMemo, useReducer } from 'react';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import head from 'lodash/head';
import isFunction from 'lodash/isFunction';
import orderBy from 'lodash/orderBy';
import { usePrevious } from 'react-hanger';
import ModalManagerContext from './Context';
import { showModal, dismissModal, setActiveModal } from './actions';
import reducer, { initialState } from './reducer';
export var ModalManagerProvider = memo(function (props) {
  var _useReducer = useReducer(reducer, initialState),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var actions = useMemo(function () {
    return bindActionCreators({
      showModal: showModal,
      dismissModal: dismissModal,
      setActiveModal: setActiveModal
    }, dispatch);
  }, [dispatch]);
  var prevActive = usePrevious(state.active);

  if (prevActive && !state.active && isFunction(prevActive.onDismiss)) {
    prevActive.onDismiss();
  }

  useEffect(function () {
    var sortedRequests = orderBy(state.requests, 'priority');
    var activeModal = head(sortedRequests);
    if (!activeModal) return;
    var hasShown = sessionStorage.getItem('modalManager_hasShown');
    if (hasShown && !activeModal.force) return;

    if (activeModal !== state.active) {
      actions.setActiveModal(activeModal); // Only show a modal once per session

      sessionStorage.setItem('modalManager_hasShown', 'true');
      var onShow = get(activeModal, 'onShow');
      if (isFunction(onShow)) onShow();
    }
  }, [actions, state.active, state.requests]);
  var modal = get(state, 'active.modal', null);
  return React.createElement(ModalManagerContext.Provider, {
    value: _objectSpread({
      modal: modal
    }, actions)
  }, props.children);
});
ModalManagerProvider.displayName = 'ModalManagerProvider';
export default ModalManagerProvider;