import { createThemeSelector } from './utils';
export var paletteSelector = createThemeSelector(function (theme) {
  return theme.palette;
});
export var paletteCommonSelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.common;
});
export var palettePrimarySelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.primary;
});
export var paletteSecondarySelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.secondary;
});
export var paletteGreySelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.grey;
});
export var paletteTextSelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.text;
});
export var paletteSuccessSelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.success;
});
export var paletteErrorSelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.error;
});
export var paletteWarnSelector = createThemeSelector(paletteSelector, function (palette) {
  return palette.warn;
});