import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import isSymbol from 'lodash/isSymbol';
import conformsTo from 'lodash/conformsTo';
import { SAGA_MODES } from './constants';
/**
 * List of valid saga modes
 * @type {Array}
 */

export var sagaModes = Object.values(SAGA_MODES);
/**
 * Expected saga descriptor shape
 * @type {Object}
 */

export var descriptorShape = {
  saga: isFunction,
  mode: function mode(_mode) {
    return isString(_mode) && sagaModes.includes(_mode);
  }
  /**
   * Validates a saga key
   * @param {String} key - A saga key
   */

};
export var validateKey = function validateKey(key) {
  return invariant(isString(key) && !isEmpty(key) || isSymbol(key), '(lib/utils...) injectSaga: Expected `key` to be a non empty string');
};
/**
 * Validates a saga descriptor
 * @param {SagaDescriptor} descriptor - A saga descriptor
 */

export var validateDescriptor = function validateDescriptor(descriptor) {
  return invariant(conformsTo(descriptor, descriptorShape), '(lib/utils...) injectSaga: Expected a valid saga descriptor');
};